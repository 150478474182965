exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-jsx": () => import("./../../../src/pages/about/index.jsx" /* webpackChunkName: "component---src-pages-about-index-jsx" */),
  "component---src-pages-contact-index-jsx": () => import("./../../../src/pages/contact/index.jsx" /* webpackChunkName: "component---src-pages-contact-index-jsx" */),
  "component---src-pages-downloads-index-jsx": () => import("./../../../src/pages/downloads/index.jsx" /* webpackChunkName: "component---src-pages-downloads-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-products-index-jsx": () => import("./../../../src/pages/products/index.jsx" /* webpackChunkName: "component---src-pages-products-index-jsx" */),
  "component---src-pages-services-index-jsx": () => import("./../../../src/pages/services/index.jsx" /* webpackChunkName: "component---src-pages-services-index-jsx" */),
  "component---src-pages-team-index-js": () => import("./../../../src/pages/team/index.js" /* webpackChunkName: "component---src-pages-team-index-js" */),
  "component---src-pages-testimonials-index-js": () => import("./../../../src/pages/testimonials/index.js" /* webpackChunkName: "component---src-pages-testimonials-index-js" */),
  "component---src-templates-product-jsx": () => import("./../../../src/templates/product.jsx" /* webpackChunkName: "component---src-templates-product-jsx" */),
  "component---src-templates-service-js": () => import("./../../../src/templates/service.js" /* webpackChunkName: "component---src-templates-service-js" */),
  "component---src-templates-team-js": () => import("./../../../src/templates/team.js" /* webpackChunkName: "component---src-templates-team-js" */),
  "component---src-templates-testimonial-js": () => import("./../../../src/templates/testimonial.js" /* webpackChunkName: "component---src-templates-testimonial-js" */)
}

